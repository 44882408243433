import axios from "axios";
import { Message } from "element-ui";
import { getToken, removeToken } from "@/utils/token";
import router from "@/router/index";

const instance = axios.create({
    baseURL: "https://api-web.bole888.vip/web",
    // baseURL: "https://api-web.yx147258.vip/web",
    timeout: 5000,
});

instance.interceptors.request.use(
    function(config) {
        if (getToken() && config.token) {
            config.headers.token = getToken();
        }
        if (config.isFormData) {
            config.headers["Content-Type"] =
                "multipart/form-data; boundary=----WebKitFormBoundaryn5rZWND3cHRxU5Ky";
        }
        return config;
    },
    function(err) {
        return console.log(err);
    }
);
instance.interceptors.response.use(function(response) {
    const { data, status, config } = response;
    if (
        data.code === 0 ||
        toString.call(data) === "[object ArrayBuffer]" ||
        (status === 200 && config.isFormData || !data.code)
    ) {
        return Promise.resolve(data);
    } else {
        switch (data.code) {
            case 401:
                router.push("/");
                removeToken();
                window.location.reload()
                break;
            default:
                Message.error(data.msg);
        }
        return;
    }
});
export function get(url, params) {
    return instance.get(url, { params });
}

export function post(url, data) {
    return instance.post(url, data);
}
export default instance;